@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  list-style-position: inside;
  letter-spacing: 1.5px;
}

body {
  font-size: 16px;
  letter-spacing: 1px;
  font-family: "Roboto", arnamu, arnamu_bold, sans-serif;
  transition: 0.5s;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #666;
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

body.left {
  margin-left: -300px;
  transition: 0.5s;
}

a {
  color: #2978e1;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1300px;
  padding: 60px 10px;
  margin: 0 auto;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 42px;
  color: #3f3f3f;
  margin: 0px 0px 20px 0px;
  text-align: center;
  letter-spacing: 8px;
  position: relative;
  text-transform: uppercase;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 90px;
  height: 2px;
  background: #3f3f3f;
}

.btnUp {
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background: #3c52a0;
  display: none;
  font-size: 30px;
  z-index: 3500;
  border-bottom: 4px solid #3c52a0;
  transition: all 0.2s;
}

.btnUp svg {
  fill: #fff;
}

.btnUp:hover {
  line-height: 40px;
}

.btnUp.btnShow {
  display: block;
}

p {
  line-height: 24px;
}

.see_more {
  display: block;
  text-decoration: none;
  margin-top: 15px;
  text-align: right;
  color: #ED3237;
}

.hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
}

.hover:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn {
  transition: 0.3s;
}

.btn:hover {
  border-color: #4960b4 !important;
  background: #4960b4 !important;
}

.btnRed {
  color: #fff;
  text-decoration: none;
  border: 2px solid #db242a;
  background: #ED3237;
  border-radius: 5px;
  transition: 0.3s;
}

.btnRed:hover {
  background: #db242a;
}

.fancybox-container {
  z-index: 9999999;
}

.wrap {
  width: 60%;
  position: relative;
  height: 0;
  margin: 10px 0 20px 0;
  overflow: hidden;
  padding-bottom: 36.25%;
}

.wrap iframe,
.wrap object,
.wrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.response_modal,
.paymentModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9898998;
}

.response_modal .rm_body,
.response_modal .py_body,
.paymentModal .rm_body,
.paymentModal .py_body {
  width: 300px;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}

.response_modal .rm_body h3,
.response_modal .py_body h3,
.paymentModal .rm_body h3,
.paymentModal .py_body h3 {
  margin-bottom: 15px;
  color: #424242;
}

.response_modal .rm_body p,
.response_modal .py_body p,
.paymentModal .rm_body p,
.paymentModal .py_body p {
  font-size: 15px;
}

.response_modal .rm_body button,
.response_modal .py_body button,
.paymentModal .rm_body button,
.paymentModal .py_body button {
  display: block;
  margin: 15px auto 0px auto;
  border: none;
  background: #4157A4;
  color: #fff;
  font-size: 15px;
  padding: 10px 35px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.response_modal .rm_body.success,
.response_modal .py_body.success,
.paymentModal .rm_body.success,
.paymentModal .py_body.success {
  color: green;
}

.response_modal .rm_body.success button,
.response_modal .py_body.success button,
.paymentModal .rm_body.success button,
.paymentModal .py_body.success button {
  background: #28A745;
}

.response_modal .rm_body.success button:hover,
.response_modal .py_body.success button:hover,
.paymentModal .rm_body.success button:hover,
.paymentModal .py_body.success button:hover {
  background: #229b3e;
}

.response_modal .rm_body.error,
.response_modal .py_body.error,
.paymentModal .rm_body.error,
.paymentModal .py_body.error {
  color: #DC3545;
}

.response_modal .rm_body.error button,
.response_modal .py_body.error button,
.paymentModal .rm_body.error button,
.paymentModal .py_body.error button {
  background: #DC3545;
}

.response_modal .rm_body.error button:hover,
.response_modal .py_body.error button:hover,
.paymentModal .rm_body.error button:hover,
.paymentModal .py_body.error button:hover {
  background: #cc2737;
}

.response_modal .rm_body.info,
.response_modal .py_body.info,
.paymentModal .rm_body.info,
.paymentModal .py_body.info {
  color: #17A2B8;
}

.response_modal .rm_body.info button,
.response_modal .py_body.info button,
.paymentModal .rm_body.info button,
.paymentModal .py_body.info button {
  background: #17A2B8;
}

.response_modal .rm_body.info button:hover,
.response_modal .py_body.info button:hover,
.paymentModal .rm_body.info button:hover,
.paymentModal .py_body.info button:hover {
  background: #0e8699;
}

.response_modal .rm_body.debug,
.response_modal .py_body.debug,
.paymentModal .rm_body.debug,
.paymentModal .py_body.debug {
  color: #FFC107;
}

.response_modal .rm_body.debug button,
.response_modal .py_body.debug button,
.paymentModal .rm_body.debug button,
.paymentModal .py_body.debug button {
  background: #FFC107;
}

.response_modal .rm_body.debug button:hover,
.response_modal .py_body.debug button:hover,
.paymentModal .rm_body.debug button:hover,
.paymentModal .py_body.debug button:hover {
  background: #daa508;
}

.donateLink {
  color: #ED3237 !important;
}

.about_pay {
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  max-width: 650px;
  margin-bottom: 15px;
  text-align: center;
}

.date_time {
  margin-top: 20px;
  font-size: 15px;
  font-style: italic;
  color: #5d5d5d;
}

header {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

header .header {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 613px;
  position: relative;
  max-width: 1300px;
  padding: 0px 10px;
  margin: 0 auto;
}

header .header .header_top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: #f1f1f1b6;
  z-index: 888888;
}

header .header .header_top .nav_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px;
}

header .header .header_top .nav_block .logo a {
  text-decoration: none;
}

header .header .header_top .nav_block .logo a img {
  width: 90px;
}

header .header .header_top .nav_block .openMenu {
  font-size: 32px;
  cursor: pointer;
  color: #3c52a0;
}

header .header .header_top .nav_block nav {
  position: absolute;
  top: 0;
  right: -500px;
  width: 350px;
  height: 100vh;
  background: #424242;
  padding: 40px 10px;
  color: #fff;
  transition: 0.5s;
  z-index: 99999;
}

header .header .header_top .nav_block nav.active_nav {
  right: 0px;
  transition: 0.5s;
}

header .header .header_top .nav_block nav .closeMenu {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
}

header .header .header_top .nav_block nav ul {
  margin-bottom: 25px;
}

header .header .header_top .nav_block nav ul li {
  list-style-type: none;
}

header .header .header_top .nav_block nav ul li a {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: 0.3s;
}

header .header .header_top .nav_block nav ul li a i {
  font-size: 14px;
  margin-right: 13px;
}

header .header .header_top .nav_block nav ul li a i.fa-circle {
  font-size: 8px;
}

header .header .header_top .nav_block nav ul li a:hover {
  margin-left: 13px;
  color: #7087dd;
}

header .header .header_top .nav_block nav .search {
  display: flex;
}

header .header .header_top .nav_block nav .search input {
  padding: 7px;
  border: 1px solid #fff;
}

header .header .header_top .nav_block nav .search button {
  padding: 7px 15px;
  border: 1px solid #4157A4;
  background: #4157A4;
  color: #fff;
  cursor: pointer;
}

header .header .header_top .nav_block nav .languages {
  margin-top: 15px;
}

header .header .header_top .nav_block nav .languages button {
  background: none;
  border: none;
  font-size: 15px;
  color: #fff;
  margin-right: 8px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: arnamu_bold;
}

header .header .header_top .nav_block nav .languages button.active_lang {
  color: #7087dd;
}

header .header .header_top .nav_block nav .languages button:hover {
  text-decoration: underline;
}

header .header .header_top .nav_block nav .nav_social {
  margin-top: 20px;
  background: #5d5d5d;
  width: fit-content;
  padding: 5px 10px;
  user-select: none;
}

header .header .header_top .nav_block nav .nav_social a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  margin-right: 15px;
  font-size: 18px;
}

header .header .header_top .nav_block nav .nav_social a:last-child {
  margin-right: 0px;
}

header .header .header_top .nav_block nav .nav_social a:hover {
  color: #7087dd;
}

header .header h1 {
  font-size: 68px;
  color: #fff;
  margin-bottom: 8px;
  text-align: center;
  text-shadow: 1px 1px 7px black;
}

header .header p {
  color: #fff;
  font-size: 24px;
  padding: 10px 14px;
  background: rgba(65, 87, 164, 0.8);
  margin-top: 8px;
  text-shadow: 1px 1px 7px black;
}

header .header .donate {
  display: block;
  margin-top: 15px;
  padding: 10px 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 1px 1px 7px black;
}

a {
  color: #2978e1;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1300px;
  padding: 60px 10px;
  margin: 0 auto;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 42px;
  color: #3f3f3f;
  margin: 0px 0px 20px 0px;
  text-align: center;
  letter-spacing: 8px;
  position: relative;
  text-transform: uppercase;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 90px;
  height: 2px;
  background: #3f3f3f;
}

.btnUp {
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background: #3c52a0;
  display: none;
  font-size: 30px;
  z-index: 3500;
  border-bottom: 4px solid #3c52a0;
  transition: all 0.2s;
}

.btnUp svg {
  fill: #fff;
}

.btnUp:hover {
  line-height: 40px;
}

.btnUp.btnShow {
  display: block;
}

p {
  line-height: 24px;
}

.see_more {
  display: block;
  text-decoration: none;
  margin-top: 15px;
  text-align: right;
  color: #ED3237;
}

.hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
}

.hover:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn {
  transition: 0.3s;
}

.btn:hover {
  border-color: #4960b4 !important;
  background: #4960b4 !important;
}

.btnRed {
  color: #fff;
  text-decoration: none;
  border: 2px solid #db242a;
  background: #ED3237;
  border-radius: 5px;
  transition: 0.3s;
}

.btnRed:hover {
  background: #db242a;
}

.fancybox-container {
  z-index: 9999999;
}

.wrap {
  width: 60%;
  position: relative;
  height: 0;
  margin: 10px 0 20px 0;
  overflow: hidden;
  padding-bottom: 36.25%;
}

.wrap iframe,
.wrap object,
.wrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.response_modal,
.paymentModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9898998;
}

.response_modal .rm_body,
.response_modal .py_body,
.paymentModal .rm_body,
.paymentModal .py_body {
  width: 300px;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}

.response_modal .rm_body h3,
.response_modal .py_body h3,
.paymentModal .rm_body h3,
.paymentModal .py_body h3 {
  margin-bottom: 15px;
  color: #424242;
}

.response_modal .rm_body p,
.response_modal .py_body p,
.paymentModal .rm_body p,
.paymentModal .py_body p {
  font-size: 15px;
}

.response_modal .rm_body button,
.response_modal .py_body button,
.paymentModal .rm_body button,
.paymentModal .py_body button {
  display: block;
  margin: 15px auto 0px auto;
  border: none;
  background: #4157A4;
  color: #fff;
  font-size: 15px;
  padding: 10px 35px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.response_modal .rm_body.success,
.response_modal .py_body.success,
.paymentModal .rm_body.success,
.paymentModal .py_body.success {
  color: green;
}

.response_modal .rm_body.success button,
.response_modal .py_body.success button,
.paymentModal .rm_body.success button,
.paymentModal .py_body.success button {
  background: #28A745;
}

.response_modal .rm_body.success button:hover,
.response_modal .py_body.success button:hover,
.paymentModal .rm_body.success button:hover,
.paymentModal .py_body.success button:hover {
  background: #229b3e;
}

.response_modal .rm_body.error,
.response_modal .py_body.error,
.paymentModal .rm_body.error,
.paymentModal .py_body.error {
  color: #DC3545;
}

.response_modal .rm_body.error button,
.response_modal .py_body.error button,
.paymentModal .rm_body.error button,
.paymentModal .py_body.error button {
  background: #DC3545;
}

.response_modal .rm_body.error button:hover,
.response_modal .py_body.error button:hover,
.paymentModal .rm_body.error button:hover,
.paymentModal .py_body.error button:hover {
  background: #cc2737;
}

.response_modal .rm_body.info,
.response_modal .py_body.info,
.paymentModal .rm_body.info,
.paymentModal .py_body.info {
  color: #17A2B8;
}

.response_modal .rm_body.info button,
.response_modal .py_body.info button,
.paymentModal .rm_body.info button,
.paymentModal .py_body.info button {
  background: #17A2B8;
}

.response_modal .rm_body.info button:hover,
.response_modal .py_body.info button:hover,
.paymentModal .rm_body.info button:hover,
.paymentModal .py_body.info button:hover {
  background: #0e8699;
}

.response_modal .rm_body.debug,
.response_modal .py_body.debug,
.paymentModal .rm_body.debug,
.paymentModal .py_body.debug {
  color: #FFC107;
}

.response_modal .rm_body.debug button,
.response_modal .py_body.debug button,
.paymentModal .rm_body.debug button,
.paymentModal .py_body.debug button {
  background: #FFC107;
}

.response_modal .rm_body.debug button:hover,
.response_modal .py_body.debug button:hover,
.paymentModal .rm_body.debug button:hover,
.paymentModal .py_body.debug button:hover {
  background: #daa508;
}

.donateLink {
  color: #ED3237 !important;
}

.about_pay {
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  max-width: 650px;
  margin-bottom: 15px;
  text-align: center;
}

.date_time {
  margin-top: 20px;
  font-size: 15px;
  font-style: italic;
  color: #5d5d5d;
}

main .sub_title {
  margin: 55px 0px 45px 0px;
  text-align: center;
}

main .our_news {
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
  text-align: center;
}

main .our_news h2 {
  color: #fff;
}

main .our_news h2::after {
  background: #fff;
}

main .our_news .see_all_news {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 10px 25px;
  border: 2px solid #fff;
  width: fit-content;
  margin: 0 auto;
  border-radius: 5px;
  transition: 0.5s;
}

main .our_news .see_all_news:hover {
  background: #fff;
  color: #4960b4;
}

main .our_news p a {
  color: #fff;
  letter-spacing: 2px;
  margin-left: 8px;
}

main .our_programs .container .programs_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 17px;
}

main .our_programs .container .programs_grid .program_preview .program_preview_cover {
  display: block;
  width: 100%;
  min-height: 220px;
  max-height: 220px;
  overflow: hidden;
}

main .our_programs .container .programs_grid .program_preview .program_preview_cover img {
  display: block;
  width: 100%;
  min-height: 220px;
  max-height: 220px;
  object-fit: cover;
}

main .our_programs .container .programs_grid .program_preview h3 {
  padding: 10px;
  text-align: center;
}

main .our_programs .container .programs_grid .program_preview h3 a {
  display: block;
  text-decoration: none;
  font-size: 15px;
  color: #4157A4;
}

main .our_programs .container .programs_grid .program_preview:hover h3 a {
  color: #ED3237;
}

main .our_programs .container .pagination {
  margin-top: 30px;
  text-align: center;
  user-select: none;
}

main .our_programs .container .pagination a {
  display: inline-block;
  text-decoration: none;
  color: #4157A4;
  font-weight: bold;
  font-size: 17px;
  margin-right: 15px;
}

main .our_programs .container .pagination a.active_pagination {
  border-bottom: 3px solid #4157A4;
}

main .subscribe_to_our_news {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}

main .subscribe_to_our_news .container h2 {
  color: #fff;
}

main .subscribe_to_our_news .container h2::after {
  background: #fff;
}

main .subscribe_to_our_news .container form {
  display: flex;
  justify-content: center;
}

main .subscribe_to_our_news .container form input {
  padding: 10px;
  border: 1px solid #fff;
}

main .subscribe_to_our_news .container form button {
  padding: 0px 25px;
  border: 2px solid #fff;
  background: #3c52a0;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: 0.5s;
  border-radius: 0px !important;
}

main .subscribe_to_our_news .container form button:hover {
  background: #fff;
  color: #4960b4;
}

main .three_steps .container .steps {
  display: flex;
  justify-content: space-evenly;
}

main .three_steps .container .steps .step {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  flex-basis: 28%;
  min-height: 250px;
  align-items: center;
  text-align: center;
}

main .three_steps .container .steps .step .step_cover {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
}

main .three_steps .container .steps .step .step_cover img {
  width: 100%;
  display: block;
}

main .three_steps .container .steps .step p {
  font-size: 15px;
  margin: 15px 0px;
  text-transform: uppercase;
  font-family: arnamu_italic;
  letter-spacing: 2px;
}

main .three_steps .container .steps .step a {
  display: block;
  text-decoration: none;
  padding: 10px 25px;
  color: #fff;
  background: #4157A4;
  border-radius: 5px;
  width: fit-content;
  margin: 0 auto;
}

main .readmore article,
main .about_ambassador article,
main .donate_page article {
  margin-top: 55px;
}

main .readmore .share {
  margin-top: 20px;
}

main .readmore .share p {
  margin-bottom: 15px;
  font-size: 15px;
  color: #424242;
}

main .about_ambassador img {
  display: block;
  float: left;
  margin: 0px 15px 8px 0px;
  width: 290px;
  height: 290px;
  object-fit: cover;
}

main .contact .contact_blocks {
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
}

main .contact .contact_blocks .contact_left {
  flex-basis: 20%;
}

main .contact .contact_blocks .contact_left div {
  margin-bottom: 20px;
}

main .contact .contact_blocks .contact_left div h3 {
  margin-bottom: 8px;
  color: #424242;
}

main .contact .contact_blocks .contact_left div p {
  margin-bottom: 5px;
  color: #4157A4;
}

main .contact .contact_blocks .contact_left div p i {
  margin-right: 15px;
}

main .contact .contact_blocks .contact_left div p a {
  text-decoration: none;
  color: #4157A4;
}

main .contact .contact_blocks .contact_right {
  flex-basis: 70%;
}

main .all_ambassadors .ambassadors {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  justify-items: center;
  grid-gap: 20px;
}

main .all_ambassadors .ambassadors .ambassador {
  text-align: center;
}

main .all_ambassadors .ambassadors .ambassador .ambassador_cover {
  display: block;
  border-radius: 50%;
  overflow: hidden;
  width: 220px;
  height: 220px;
  margin-bottom: 12px;
  transition: 0.2s;
  background: transparent;
}

main .all_ambassadors .ambassadors .ambassador .ambassador_cover img {
  width: 100%;
}

main .all_ambassadors .ambassadors .ambassador h3 a {
  text-decoration: none;
  color: #4157A4;
  text-transform: uppercase;
}

main .all_ambassadors .ambassadors .ambassador p {
  margin-top: 8px;
  font-style: italic;
  font-size: 15px;
}

main .all_ambassadors .ambassadors .ambassador:hover .ambassador_cover {
  padding: 15px;
  background: #4157A4;
  transition: 0.3s;
}

main .all_ambassadors .ambassadors .ambassador:hover h3 a {
  text-decoration: underline;
}

main .apply form {
  display: flex;
  flex-flow: column;
  max-width: 500px;
  margin: 0 auto;
}

main .apply form input,
main .apply form textarea {
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 13px;
}

main .apply form button {
  padding: 10px;
  background: #4157A4;
  color: #fff;
  border: 1px solid #4960b4;
  cursor: pointer;
  border-radius: 5px;
}

main .apply form textarea {
  max-width: 500px;
  min-width: 100%;
  max-height: 400px;
  min-height: 200px;
  font-family: arnamu;
  letter-spacing: 2px;
  font-size: 14px;
}

main .about_program .title {
  font-size: 30px;
}

main .about_program article {
  margin-top: 50px;
}

main .about_program article img {
  display: block;
  float: left;
  margin: 0px 15px 8px 0px;
  width: 290px;
  height: 290px;
  object-fit: cover;
}

main .about_program #fancy {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 17px;
  margin-top: 20px;
  z-index: 999999;
}

main .about_program #fancy a {
  display: block;
  min-height: 155px;
}

main .about_program #fancy a img {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: block;
  object-fit: cover;
}

main .about_program .liveTitle {
  margin-bottom: 25px;
  margin-top: 45px;
  font-size: 24px;
  color: #424242;
}

main .about_program .share {
  margin-top: 20px;
}

main .about_program .share p {
  margin-bottom: 15px;
  font-size: 15px;
  color: #424242;
}

main .donate_page .tabs .tab_btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
}

main .donate_page .tabs .tab_btns .tab_btn {
  flex-basis: calc(100%/3);
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  background: rgba(65, 87, 164, 0.4);
  color: #000;
  cursor: pointer;
}

main .donate_page .tabs .tab_btns .tab_btn.active_tab {
  background: #4157A4;
  color: #fff;
}

main .donate_page .tabs .tab_bodys {
  margin-top: 20px;
}

main .donate_page .tabs .tab_bodys .tab_body {
  display: none;
}

main .donate_page .tabs .tab_bodys .tab_body.active_tab_body {
  display: block;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block {
  margin-bottom: 15px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .alert {
  font-size: 14px;
  margin-bottom: 10px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .alert span {
  color: #ED3237;
  margin-right: 5px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block h3 {
  margin-bottom: 10px;
  color: #111111;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block input {
  padding: 7px 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_ex .exchanges {
  display: flex;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_ex .exchanges .ex_label {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-top: 5px;
  margin-right: 10px;
  background: #bbb;
  color: #fff;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_ex .exchanges .ex_label .ex_icon {
  font-size: 20px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_ex .exchanges .ex_label .ex_name {
  font-size: 14px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_ex .exchanges .ex_label input {
  display: none;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_ex .exchanges .ex_label.active_label {
  background: #4157A4;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_anonym_status .anonym {
  display: flex;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_anonym_status .anonym .an_label {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  margin-top: 5px;
  margin-right: 10px;
  background: #bbb;
  color: #fff;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_anonym_status .anonym .an_label .ex_icon {
  font-size: 20px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_anonym_status .anonym .an_label .ex_name {
  font-size: 14px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_anonym_status .anonym .an_label input {
  display: none;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .choose_anonym_status .anonym .an_label.active_label {
  background: #4157A4;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .select {
  width: fit-content;
  position: relative;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .select .selected {
  background: #3c52a0;
  padding: 10px;
  color: #111111;
  cursor: pointer;
  color: #fff;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .select .options {
  display: none;
  position: absolute;
  top: 37px;
  left: 0;
  background: #4960b4;
  width: 100%;
  color: #fff;
  font-size: 14px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .select .options label {
  display: block;
  cursor: pointer;
  padding: 10px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .select .options label input {
  display: none;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .select .options label:hover {
  background: #4157A4;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .rekvisit {
  margin-top: 30px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .rekvisit .rekvisit_tab {
  display: none;
  justify-content: space-between;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .rekvisit .rekvisit_tab.active_rekvisit {
  display: flex;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .rekvisit .rekvisit_tab .r_block .r_block_t {
  font-weight: bold;
  margin-bottom: 8px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block .rekvisit .rekvisit_tab .r_block p {
  margin-bottom: 5px;
}

main .donate_page .tabs .tab_bodys .tab_body .tab_block:nth-of-type(4) {
  display: flex;
  flex-flow: column;
}

main .donate_page .tabs .tab_bodys .tab_body input[type=submit] {
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}

main .news_page .all_news {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 25px;
  margin-top: 55px;
}

main .news_page .all_news .news {
  display: block;
  padding: 10px;
  text-decoration: none;
  margin-bottom: 25px;
}

main .news_page .all_news .news h3 {
  margin-bottom: 5px;
  color: #4157A4;
}

main .news_page .all_news .news p {
  color: #424242;
}

main .news_page .all_news .news:hover h3 {
  text-decoration: underline;
}

main .search_page h1 {
  font-size: 25px;
}

main .search_page #search {
  margin-top: 55px;
}

main .search_page #search .search_resault {
  margin-bottom: 15px;
}

main .search_page #search .search_resault a {
  display: block;
  margin-bottom: 5px;
}

main .search_page #search .search_resault p {
  font-size: 14px;
  color: #424242;
}

main .reports_page .reports a {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

main .reports_page .reports a img {
  margin-right: 5px;
  width: 38px;
  height: 38px;
}

main .donors_page .donors {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 25px;
}

main .donors_page .donors .donor {
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
}

main .donors_page .donors .donor p span {
  font-family: arnamu_italic;
  color: #4157A4;
}

main .donors_page .donors .donor p:nth-child(2) {
  font-size: 12px;
  margin-top: 4px;
  font-family: arnamu_italic;
}

a {
  color: #2978e1;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1300px;
  padding: 60px 10px;
  margin: 0 auto;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 42px;
  color: #3f3f3f;
  margin: 0px 0px 20px 0px;
  text-align: center;
  letter-spacing: 8px;
  position: relative;
  text-transform: uppercase;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 90px;
  height: 2px;
  background: #3f3f3f;
}

.btnUp {
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background: #3c52a0;
  display: none;
  font-size: 30px;
  z-index: 3500;
  border-bottom: 4px solid #3c52a0;
  transition: all 0.2s;
}

.btnUp svg {
  fill: #fff;
}

.btnUp:hover {
  line-height: 40px;
}

.btnUp.btnShow {
  display: block;
}

p {
  line-height: 24px;
}

.see_more {
  display: block;
  text-decoration: none;
  margin-top: 15px;
  text-align: right;
  color: #ED3237;
}

.hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
}

.hover:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn {
  transition: 0.3s;
}

.btn:hover {
  border-color: #4960b4 !important;
  background: #4960b4 !important;
}

.btnRed {
  color: #fff;
  text-decoration: none;
  border: 2px solid #db242a;
  background: #ED3237;
  border-radius: 5px;
  transition: 0.3s;
}

.btnRed:hover {
  background: #db242a;
}

.fancybox-container {
  z-index: 9999999;
}

.wrap {
  width: 60%;
  position: relative;
  height: 0;
  margin: 10px 0 20px 0;
  overflow: hidden;
  padding-bottom: 36.25%;
}

.wrap iframe,
.wrap object,
.wrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.response_modal,
.paymentModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9898998;
}

.response_modal .rm_body,
.response_modal .py_body,
.paymentModal .rm_body,
.paymentModal .py_body {
  width: 300px;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}

.response_modal .rm_body h3,
.response_modal .py_body h3,
.paymentModal .rm_body h3,
.paymentModal .py_body h3 {
  margin-bottom: 15px;
  color: #424242;
}

.response_modal .rm_body p,
.response_modal .py_body p,
.paymentModal .rm_body p,
.paymentModal .py_body p {
  font-size: 15px;
}

.response_modal .rm_body button,
.response_modal .py_body button,
.paymentModal .rm_body button,
.paymentModal .py_body button {
  display: block;
  margin: 15px auto 0px auto;
  border: none;
  background: #4157A4;
  color: #fff;
  font-size: 15px;
  padding: 10px 35px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.response_modal .rm_body.success,
.response_modal .py_body.success,
.paymentModal .rm_body.success,
.paymentModal .py_body.success {
  color: green;
}

.response_modal .rm_body.success button,
.response_modal .py_body.success button,
.paymentModal .rm_body.success button,
.paymentModal .py_body.success button {
  background: #28A745;
}

.response_modal .rm_body.success button:hover,
.response_modal .py_body.success button:hover,
.paymentModal .rm_body.success button:hover,
.paymentModal .py_body.success button:hover {
  background: #229b3e;
}

.response_modal .rm_body.error,
.response_modal .py_body.error,
.paymentModal .rm_body.error,
.paymentModal .py_body.error {
  color: #DC3545;
}

.response_modal .rm_body.error button,
.response_modal .py_body.error button,
.paymentModal .rm_body.error button,
.paymentModal .py_body.error button {
  background: #DC3545;
}

.response_modal .rm_body.error button:hover,
.response_modal .py_body.error button:hover,
.paymentModal .rm_body.error button:hover,
.paymentModal .py_body.error button:hover {
  background: #cc2737;
}

.response_modal .rm_body.info,
.response_modal .py_body.info,
.paymentModal .rm_body.info,
.paymentModal .py_body.info {
  color: #17A2B8;
}

.response_modal .rm_body.info button,
.response_modal .py_body.info button,
.paymentModal .rm_body.info button,
.paymentModal .py_body.info button {
  background: #17A2B8;
}

.response_modal .rm_body.info button:hover,
.response_modal .py_body.info button:hover,
.paymentModal .rm_body.info button:hover,
.paymentModal .py_body.info button:hover {
  background: #0e8699;
}

.response_modal .rm_body.debug,
.response_modal .py_body.debug,
.paymentModal .rm_body.debug,
.paymentModal .py_body.debug {
  color: #FFC107;
}

.response_modal .rm_body.debug button,
.response_modal .py_body.debug button,
.paymentModal .rm_body.debug button,
.paymentModal .py_body.debug button {
  background: #FFC107;
}

.response_modal .rm_body.debug button:hover,
.response_modal .py_body.debug button:hover,
.paymentModal .rm_body.debug button:hover,
.paymentModal .py_body.debug button:hover {
  background: #daa508;
}

.donateLink {
  color: #ED3237 !important;
}

.about_pay {
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  max-width: 650px;
  margin-bottom: 15px;
  text-align: center;
}

.date_time {
  margin-top: 20px;
  font-size: 15px;
  font-style: italic;
  color: #5d5d5d;
}

a {
  color: #2978e1;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1300px;
  padding: 60px 10px;
  margin: 0 auto;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 42px;
  color: #3f3f3f;
  margin: 0px 0px 20px 0px;
  text-align: center;
  letter-spacing: 8px;
  position: relative;
  text-transform: uppercase;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 90px;
  height: 2px;
  background: #3f3f3f;
}

.btnUp {
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background: #3c52a0;
  display: none;
  font-size: 30px;
  z-index: 3500;
  border-bottom: 4px solid #3c52a0;
  transition: all 0.2s;
}

.btnUp svg {
  fill: #fff;
}

.btnUp:hover {
  line-height: 40px;
}

.btnUp.btnShow {
  display: block;
}

p {
  line-height: 24px;
}

.see_more {
  display: block;
  text-decoration: none;
  margin-top: 15px;
  text-align: right;
  color: #ED3237;
}

.hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
}

.hover:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn {
  transition: 0.3s;
}

.btn:hover {
  border-color: #4960b4 !important;
  background: #4960b4 !important;
}

.btnRed {
  color: #fff;
  text-decoration: none;
  border: 2px solid #db242a;
  background: #ED3237;
  border-radius: 5px;
  transition: 0.3s;
}

.btnRed:hover {
  background: #db242a;
}

.fancybox-container {
  z-index: 9999999;
}

.wrap {
  width: 60%;
  position: relative;
  height: 0;
  margin: 10px 0 20px 0;
  overflow: hidden;
  padding-bottom: 36.25%;
}

.wrap iframe,
.wrap object,
.wrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.response_modal,
.paymentModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9898998;
}

.response_modal .rm_body,
.response_modal .py_body,
.paymentModal .rm_body,
.paymentModal .py_body {
  width: 300px;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}

.response_modal .rm_body h3,
.response_modal .py_body h3,
.paymentModal .rm_body h3,
.paymentModal .py_body h3 {
  margin-bottom: 15px;
  color: #424242;
}

.response_modal .rm_body p,
.response_modal .py_body p,
.paymentModal .rm_body p,
.paymentModal .py_body p {
  font-size: 15px;
}

.response_modal .rm_body button,
.response_modal .py_body button,
.paymentModal .rm_body button,
.paymentModal .py_body button {
  display: block;
  margin: 15px auto 0px auto;
  border: none;
  background: #4157A4;
  color: #fff;
  font-size: 15px;
  padding: 10px 35px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.response_modal .rm_body.success,
.response_modal .py_body.success,
.paymentModal .rm_body.success,
.paymentModal .py_body.success {
  color: green;
}

.response_modal .rm_body.success button,
.response_modal .py_body.success button,
.paymentModal .rm_body.success button,
.paymentModal .py_body.success button {
  background: #28A745;
}

.response_modal .rm_body.success button:hover,
.response_modal .py_body.success button:hover,
.paymentModal .rm_body.success button:hover,
.paymentModal .py_body.success button:hover {
  background: #229b3e;
}

.response_modal .rm_body.error,
.response_modal .py_body.error,
.paymentModal .rm_body.error,
.paymentModal .py_body.error {
  color: #DC3545;
}

.response_modal .rm_body.error button,
.response_modal .py_body.error button,
.paymentModal .rm_body.error button,
.paymentModal .py_body.error button {
  background: #DC3545;
}

.response_modal .rm_body.error button:hover,
.response_modal .py_body.error button:hover,
.paymentModal .rm_body.error button:hover,
.paymentModal .py_body.error button:hover {
  background: #cc2737;
}

.response_modal .rm_body.info,
.response_modal .py_body.info,
.paymentModal .rm_body.info,
.paymentModal .py_body.info {
  color: #17A2B8;
}

.response_modal .rm_body.info button,
.response_modal .py_body.info button,
.paymentModal .rm_body.info button,
.paymentModal .py_body.info button {
  background: #17A2B8;
}

.response_modal .rm_body.info button:hover,
.response_modal .py_body.info button:hover,
.paymentModal .rm_body.info button:hover,
.paymentModal .py_body.info button:hover {
  background: #0e8699;
}

.response_modal .rm_body.debug,
.response_modal .py_body.debug,
.paymentModal .rm_body.debug,
.paymentModal .py_body.debug {
  color: #FFC107;
}

.response_modal .rm_body.debug button,
.response_modal .py_body.debug button,
.paymentModal .rm_body.debug button,
.paymentModal .py_body.debug button {
  background: #FFC107;
}

.response_modal .rm_body.debug button:hover,
.response_modal .py_body.debug button:hover,
.paymentModal .rm_body.debug button:hover,
.paymentModal .py_body.debug button:hover {
  background: #daa508;
}

.donateLink {
  color: #ED3237 !important;
}

.about_pay {
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  max-width: 650px;
  margin-bottom: 15px;
  text-align: center;
}

.date_time {
  margin-top: 20px;
  font-size: 15px;
  font-style: italic;
  color: #5d5d5d;
}

footer {
  background: #424242;
}

footer .footer {
  display: flex;
  justify-content: space-between;
}

footer .footer .f_block {
  flex-basis: 24%;
  text-align: center;
}

footer .footer .f_block a img {
  width: 200px;
}

footer .footer .f_block p {
  font-size: 13px;
  color: #fff;
  margin-top: 15px;
}

footer .footer .f_block h3 {
  color: #7087dd;
  margin-bottom: 10px;
}

footer .footer .f_block ul li {
  list-style-type: none;
}

footer .footer .f_block ul li a {
  display: block;
  padding: 8px;
  color: #fff;
  text-decoration: none;
}

footer .footer .f_block ul li a:hover {
  text-decoration: underline;
  color: #7087dd;
}

footer .pay_logo {
  margin: 0px auto 25px auto;
  max-width: 350px;
  padding: 0px 10px;
}

footer .pay_logo img {
  width: 100%;
}

footer .create {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  border-top: 1px solid #5d5d5d;
}

footer .create a {
  color: #7087dd;
}

a {
  color: #2978e1;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1300px;
  padding: 60px 10px;
  margin: 0 auto;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 42px;
  color: #3f3f3f;
  margin: 0px 0px 20px 0px;
  text-align: center;
  letter-spacing: 8px;
  position: relative;
  text-transform: uppercase;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 90px;
  height: 2px;
  background: #3f3f3f;
}

.btnUp {
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background: #3c52a0;
  display: none;
  font-size: 30px;
  z-index: 3500;
  border-bottom: 4px solid #3c52a0;
  transition: all 0.2s;
}

.btnUp svg {
  fill: #fff;
}

.btnUp:hover {
  line-height: 40px;
}

.btnUp.btnShow {
  display: block;
}

p {
  line-height: 24px;
}

.see_more {
  display: block;
  text-decoration: none;
  margin-top: 15px;
  text-align: right;
  color: #ED3237;
}

.hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
}

.hover:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn {
  transition: 0.3s;
}

.btn:hover {
  border-color: #4960b4 !important;
  background: #4960b4 !important;
}

.btnRed {
  color: #fff;
  text-decoration: none;
  border: 2px solid #db242a;
  background: #ED3237;
  border-radius: 5px;
  transition: 0.3s;
}

.btnRed:hover {
  background: #db242a;
}

.fancybox-container {
  z-index: 9999999;
}

.wrap {
  width: 60%;
  position: relative;
  height: 0;
  margin: 10px 0 20px 0;
  overflow: hidden;
  padding-bottom: 36.25%;
}

.wrap iframe,
.wrap object,
.wrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.response_modal,
.paymentModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9898998;
}

.response_modal .rm_body,
.response_modal .py_body,
.paymentModal .rm_body,
.paymentModal .py_body {
  width: 300px;
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}

.response_modal .rm_body h3,
.response_modal .py_body h3,
.paymentModal .rm_body h3,
.paymentModal .py_body h3 {
  margin-bottom: 15px;
  color: #424242;
}

.response_modal .rm_body p,
.response_modal .py_body p,
.paymentModal .rm_body p,
.paymentModal .py_body p {
  font-size: 15px;
}

.response_modal .rm_body button,
.response_modal .py_body button,
.paymentModal .rm_body button,
.paymentModal .py_body button {
  display: block;
  margin: 15px auto 0px auto;
  border: none;
  background: #4157A4;
  color: #fff;
  font-size: 15px;
  padding: 10px 35px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.response_modal .rm_body.success,
.response_modal .py_body.success,
.paymentModal .rm_body.success,
.paymentModal .py_body.success {
  color: green;
}

.response_modal .rm_body.success button,
.response_modal .py_body.success button,
.paymentModal .rm_body.success button,
.paymentModal .py_body.success button {
  background: #28A745;
}

.response_modal .rm_body.success button:hover,
.response_modal .py_body.success button:hover,
.paymentModal .rm_body.success button:hover,
.paymentModal .py_body.success button:hover {
  background: #229b3e;
}

.response_modal .rm_body.error,
.response_modal .py_body.error,
.paymentModal .rm_body.error,
.paymentModal .py_body.error {
  color: #DC3545;
}

.response_modal .rm_body.error button,
.response_modal .py_body.error button,
.paymentModal .rm_body.error button,
.paymentModal .py_body.error button {
  background: #DC3545;
}

.response_modal .rm_body.error button:hover,
.response_modal .py_body.error button:hover,
.paymentModal .rm_body.error button:hover,
.paymentModal .py_body.error button:hover {
  background: #cc2737;
}

.response_modal .rm_body.info,
.response_modal .py_body.info,
.paymentModal .rm_body.info,
.paymentModal .py_body.info {
  color: #17A2B8;
}

.response_modal .rm_body.info button,
.response_modal .py_body.info button,
.paymentModal .rm_body.info button,
.paymentModal .py_body.info button {
  background: #17A2B8;
}

.response_modal .rm_body.info button:hover,
.response_modal .py_body.info button:hover,
.paymentModal .rm_body.info button:hover,
.paymentModal .py_body.info button:hover {
  background: #0e8699;
}

.response_modal .rm_body.debug,
.response_modal .py_body.debug,
.paymentModal .rm_body.debug,
.paymentModal .py_body.debug {
  color: #FFC107;
}

.response_modal .rm_body.debug button,
.response_modal .py_body.debug button,
.paymentModal .rm_body.debug button,
.paymentModal .py_body.debug button {
  background: #FFC107;
}

.response_modal .rm_body.debug button:hover,
.response_modal .py_body.debug button:hover,
.paymentModal .rm_body.debug button:hover,
.paymentModal .py_body.debug button:hover {
  background: #daa508;
}

.donateLink {
  color: #ED3237 !important;
}

.about_pay {
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  max-width: 650px;
  margin-bottom: 15px;
  text-align: center;
}

.date_time {
  margin-top: 20px;
  font-size: 15px;
  font-style: italic;
  color: #5d5d5d;
}

@font-face {
  font-family: arnamu;
  src: url("../fonts/arnamu.ttf");
}

@font-face {
  font-family: arnamu_bold;
  src: url("../fonts/arnamu_bold.ttf");
}

@font-face {
  font-family: arnamu_italic_bold;
  src: url("../fonts/arnamu_italic_bold.ttf");
}

@font-face {
  font-family: arnamu_italic;
  src: url("../fonts/arnamu_italic.ttf");
}

@media screen and (min-width: 1921px) {
  header .header .header_top .nav_block nav {
    width: 500px;
  }
}

@media screen and (min-width: 2400px) {
  header .header .header_top .nav_block nav {
    width: 700px;
  }
}

@media screen and (max-width: 1290px) {
  main .donate_page .tabs .tab_btns .tab_btn {
    padding: 8px;
    font-size: 15px;
  }

  main .donate_page .tabs .tab_bodys .tab_body .tab_body_item .tab_body_item__details {
    flex-basis: 400px;
  }
}

@media screen and (max-width: 1220px) {
  main .donate_page .tabs .tab_bodys .tab_body .tab_block .rekvisit .rekvisit_tab .r_block {
    margin-right: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .wrap {
    width: 100%;
    padding-bottom: 56.25%;
  }

  .wrap {
    width: 100%;
    padding-bottom: 56.25%;
  }

  main .donate_page .tabs .tab_btns .tab_btn {
    padding: 10px;
    font-size: unset;
  }

  main .donors_page .donors {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  main .donors_page .donors .donor p {
    font-size: 15px;
  }

  .wrap {
    width: 100%;
    padding-bottom: 56.25%;
  }

  .wrap {
    width: 100%;
    padding-bottom: 56.25%;
  }

  .wrap {
    width: 100%;
    padding-bottom: 56.25%;
  }
}

@media screen and (max-width: 920px) {
  footer .footer {
    flex-wrap: wrap;
  }

  footer .footer .f_block {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 24px;
  }

  header .header .header_top .nav_block nav {
    width: 300px;
  }

  .title {
    font-size: 24px;
  }

  main .three_steps .container .steps {
    flex-wrap: wrap;
  }

  main .three_steps .container .steps .step {
    flex-basis: 100%;
    margin-bottom: 30px;
  }

  main .contact .contact_blocks {
    flex-wrap: wrap;
  }

  main .contact .contact_blocks .contact_left {
    margin-bottom: 25px;
  }

  main .contact .contact_blocks .contact_left,
  main .contact .contact_blocks .contact_right {
    flex-basis: 100%;
  }

  main .about_program .title {
    font-size: 24px;
  }

  main .donate_page .tabs .tab_bodys .tab_body .tab_block .rekvisit .rekvisit_tab {
    flex-direction: column;
  }

  main .donate_page .tabs .tab_bodys .tab_body .tab_block .rekvisit .rekvisit_tab .r_block {
    margin-bottom: 40px;
    margin-right: 0px;
  }

  main .search_page h1 {
    font-size: 24px;
  }

  .title {
    font-size: 24px;
  }

  .title {
    font-size: 24px;
  }

  footer .create {
    flex-flow: column;
    align-items: center;
  }

  footer .create p {
    margin-bottom: 5px;
    text-align: center;
  }

  .title {
    font-size: 24px;
  }
}

@media screen and (max-width: 680px) {
  main .donors_page .donors {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  main .donors_page .donors .donor p {
    font-size: 15px;
  }
}

@media screen and (max-width: 640px) {
  header .header h1 {
    font-size: 58px;
  }

  header .header p {
    font-size: 21px;
  }
}

@media screen and (max-width: 580px) {
  header .header {
    height: 400px;
  }

  header .header .header_top .nav_block .logo a img {
    width: 60px;
  }

  header .header h1 {
    font-size: 50px;
  }

  header .header p {
    font-size: 19px;
  }

  main .about_ambassador img {
    width: 100%;
    height: 100%;
    margin: 0px 0px 15px 0px;
  }

  main .about_program article img {
    width: 100%;
    height: 100%;
    margin: 0px 0px 15px 0px;
  }

  main .donate_page .tabs .tab_btns {
    flex-wrap: wrap;
  }

  main .donate_page .tabs .tab_btns .tab_btn {
    flex-basis: calc(100%/1);
  }
}

@media screen and (max-width: 520px) {
  .title {
    font-size: 20px;
  }

  .title {
    font-size: 20px;
  }

  main .about_program .title {
    font-size: 20px;
  }

  main .search_page h1 {
    font-size: 20px;
  }

  .title {
    font-size: 20px;
  }

  .title {
    font-size: 20px;
  }

  .title {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 18px;
  }

  .title {
    font-size: 18px;
  }

  main .about_program .title {
    font-size: 18px;
  }

  main .search_page h1 {
    font-size: 18px;
  }

  .title {
    font-size: 18px;
  }

  .title {
    font-size: 18px;
  }

  .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 420px) {
  header .header h1 {
    font-size: 40px;
  }

  header .header p {
    font-size: 17px;
  }
}

@media screen and (max-width: 380px) {
  .title {
    font-size: 16px;
  }

  .title {
    font-size: 16px;
  }

  .title {
    font-size: 16px;
  }

  .title {
    font-size: 16px;
  }

  .title {
    font-size: 16px;
  }
}

@media screen and (max-width: 350px) {
  header .header h1 {
    font-size: 30px;
  }

  header .header p {
    font-size: 15px;
  }
}

@media screen and (max-width: 340px) {
  .title {
    font-size: 15px;
  }

  .title {
    font-size: 15px;
  }

  .title {
    font-size: 15px;
  }

  .title {
    font-size: 15px;
  }

  .title {
    font-size: 15px;
  }
}